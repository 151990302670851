/* eslint-disable no-tabs */
import L from 'leaflet'
import './edit-simple-shape'

L.Edit = L.Edit || {}
/**
 * @class L.Edit.CircleMarker
 * @aka Edit.Circle
 * @inherits L.Edit.SimpleShape
 */
L.Edit.CircleMarker = L.Edit.SimpleShape.extend({
	_createMoveMarker: function () {
		const center = this._shape.getLatLng()

		this._moveMarker = this._createMarker(center, this.options.moveIcon)
	},

	_createResizeMarker: function () {
		// To avoid an undefined check in L.Edit.SimpleShape.removeHooks
		this._resizeMarkers = []
	},

	_move: function (latlng: any) {
		if (this._resizeMarkers.length) {
			const resizemarkerPoint = this._getResizeMarkerPoint(latlng)
			// Move the resize marker
			this._resizeMarkers[0].setLatLng(resizemarkerPoint)
        }

        if (this._restrict) {
            this._restrict.setLatLng(latlng)
        }

		// Move the circle
		this._shape.setLatLng(latlng)

		this._map.fire(L.Draw.Event.EDITMOVE, { layer: this._shape })
	},
})

L.CircleMarker.addInitHook(function (this: any) {
	if (L.Edit.CircleMarker) {
		this.editing = new L.Edit.CircleMarker(this)

		if (this.options.editable) {
			this.editing.enable()
		}
	}

	this.on('add', function (this: any) {
		if (this.editing && this.editing.enabled()) {
			this.editing.addHooks()
		}
	})

	this.on('remove', function (this: any) {
		if (this.editing && this.editing.enabled()) {
			this.editing.removeHooks()
		}
	})
})
